:root {
  --background-primary: #282c34;
  --text-primary: #f3f3f3;
  --text-secondary: #edeeef;
}

[component-theme='light'] {
  --background-primary: #f3f3f3;
  --text-primary: #2b2b2b;
  --text-secondary: #3d3d3d;
}

body {
  margin: 0;
}
