.app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-primary);
}

.app-header {
  text-align: center;
  color: var(--text-primary);
  font-size: calc(50px + 2vmin);
  font-family: 'Lobster', cursive;
  cursor: pointer;
  background: none;
  background-color: none;
  border: none;
  padding: 0;
  outline: none;
}
